var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pt-2",attrs:{"flat":""}},[_c('v-col',{staticClass:"mt-5",attrs:{"cols":"12","sm":"8"}},[_c('v-toolbar-title',{staticClass:"error--text text-uppercase font-weight-bold text--darken-1 justify-center"},[_c('v-icon',{staticClass:"mr-2 mt-n1",attrs:{"color":"error"}},[_vm._v("mdi-account-group")]),_vm._v("Status ")],1),_c('p',{staticClass:"grey--text ml-8 mt-n2"},[_vm._v("Tender Status Management")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 text-capitalize",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v(" New ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"error--text text--darken-1"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-divider'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Status Name","rules":_vm.nameRules,"outlined":"","dense":"","color":"grey"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"label":"Code","rules":_vm.nameRules,"outlined":"","dense":"","color":"grey"},model:{value:(_vm.editedItem.slug),callback:function ($$v) {_vm.$set(_vm.editedItem, "slug", $$v)},expression:"editedItem.slug"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize mx-1",attrs:{"color":"error","dark":""},on:{"click":_vm.close}},[_vm._v(" Cancel "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cancel")])],1),_c('v-btn',{staticClass:"text-capitalize mx-1",attrs:{"color":"primary","dark":"","loading":_vm.loading},on:{"click":_vm.save}},[_vm._v(" Submit "),_c('v-icon',[_vm._v("mdi-content-save-outline")])],1)],1)],1)],1)],1)],1),_c('v-divider')]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.create_at))+" ")]}},{key:"item.company",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCompanyName(item.company))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"color":"grey","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"error","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }